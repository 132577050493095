@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

/* Navbar */
.navbar {
	font-family: "Lato", sans-serif;
	font-size: medium;
	font-weight: 400;
	margin: 0px 75px;
}

@media (max-width: 768px) {
	.navbar {
		margin: 0;
	}
}

.navbar-name {
	color: black !important;
	font-family: "Lato", sans-serif;
	font-size: medium;
	font-weight: 700;
}

.navbar-name:hover {
	color: #ff5c59 !important;
}

.navbar-link {
	color: black !important;
}

.navbar-link:hover {
	color: #ff5c59 !important;
	text-decoration: none;
}

.collapse {
	justify-content: center;
}

/* Landing Page */
.center {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	transform: translateY(-50%);
	margin: auto;
	width: 90%;
}

@media (min-width: 768px) {
	.center {
		width: 80%;
	}
}

@media (min-width: 1200px) {
	.center {
		width: 60%;
	}
}

.greeting {
	color: black !important;
	font-family: "Open Sans", sans-serif;
	font-size: 40px;
	font-weight: 600;
	text-align: left;
}

.typist .Cursor {
	display: inline-block;
}

.typist .Cursor--blinking {
	opacity: 1;
	animation: blink 1s linear infinite;
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.intro {
	color: black !important;
	font-family: "Lato", sans-serif;
	font-size: medium;
	font-weight: 400;
	text-align: left;
}

.underline {
	color: black;
	background: linear-gradient(to top, #ffbb8e 30%, transparent 0%);
}

.underline:hover {
	color: black;
	text-decoration: none;
	background: linear-gradient(to top, #ffbb8e 100%, transparent 0%);
}

.about-bottom {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 90%;
}

@media (min-width: 768px) {
	.about-bottom {
		width: 80%;
	}
}

@media (min-width: 1200px) {
	.about-bottom {
		width: 60%;
	}
}

/* Photography */
.center-photography {
	padding: 0;
	margin: auto;
	width: 90%;
}

@media (min-width: 768px) {
	.center-photography {
		width: 80%;
	}
}

@media (min-width: 1200px) {
	.center-photography {
		width: 60%;
	}
}

.back-paragraph {
	font-family: "Open Sans", sans-serif;
	font-size: medium;
	font-weight: 600;
	text-align: left;
}

.back-button {
	color: black;
}

.back-button:hover {
	color: black;
	text-decoration: underline;
}

.header {
	color: black !important;
	font-family: "Open Sans", sans-serif;
	font-size: 30px;
	font-weight: 600;
	text-align: left;
}

.images-container {
	margin: 0;
}

.image {
	max-width: 100%;
	padding: 0px;
}

.location {
	color: black;
	font-family: "Lato", sans-serif;
	font-size: x-small;
	font-weight: 400;
	text-align: right;
}

/* Footer */
.footer {
	padding: 25px;
	margin: 0;
}

.links {
	text-align: center;
}

.icon {
	color: black !important;
	margin: 0 14px;
}

.icon:hover {
	color: #ff7600 !important;
	margin: 0 14px;
}

.copyright {
	color: black !important;
	font-family: "Lato", sans-serif;
	font-size: small;
	font-weight: 400;
	text-align: center;
	margin: 12px 0 0 0;
}

@media (min-width: 768px) {
	.links {
		text-align: left;
	}

	.copyright {
		text-align: right;
		margin-top: 0;
	}
}
